$current-time-color: rgb(16 128 162);

@import '~react-big-calendar/lib/sass/styles';

.rbc-calendar {
  @apply bg-white text-subtitle;
}

.rbc-event-label {
  @apply hidden;
}

.rbc-event {
  @apply rounded-none !border-none !bg-transparent p-0 text-black !outline-none;
}

#dayCalendar .rbc-day-slot .rbc-timeslot-group,
#dayCalendar .rbc-allday-cell .rbc-row {
  @apply bg-white;
}
.rbc-time-slot {
  @apply min-h-[3rem];
}

.rbc-header {
  @apply bg-subtitle/20;
}

// .rbc-calendar {
//   @apply flex h-full flex-col bg-white;
// }
// .rbc-header {
//   @apply flex flex-row items-center justify-center bg-gray-200 p-4 text-center font-medium text-gray-500;
// }
// .rbc-active {
//   @apply bg-primary text-white;
// }
// .rbc-button-link {
//   @apply flex flex-row font-normal text-gray-500;
// }
// .rbc-row {
//   @apply flex flex-row;
// }
// .rbc-row-content-scrollable {
//   @apply flex h-full flex-col;
// }
// /*Month*/
// .rbc-day-bg,
// .rbc-off-range-bg {
//   @apply bg-white;
// }
// .rbc-month-row {
//   @apply pb-20;
// }
// /*Week*/
// .rbc-event {
//   @apply flex-col-reverse rounded-none border-l-4 border-primary bg-primary/[0.25] p-2 text-black;
// }
// .rbc-event-content {
//   @apply font-medium;
// }
// .rbc-selected {
//   @apply border-white bg-white;
// }
// .rbc-today {
//   @apply bg-lightblue;
// }
