@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');

@layer base {
  html,
  body,
  #root {
    @apply h-full;
  }

  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='date'],
  textarea,
  select {
    @apply block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm;
  }

  input[type='radio'],
  input[type='checkbox'] {
    @apply text-primary accent-primary focus:ring-primary;
  }

  input[type='text'].invalid,
  input[type='number'].invalid,
  input[type='email'].invalid,
  input[type='password'].invalid,
  input[type='tel'].invalid,
  input[type='date'].invalid,
  textarea.invalid,
  select.invalid {
    @apply invalid:border-red-500 invalid:focus:border-red-500 invalid:focus:ring-red-500;
  }

  label {
    @apply mb-1 block text-sm font-medium text-gray-700;
  }
}

@layer components {
  .btn {
    @apply flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm;
  }

  .btn-primary {
    @apply bg-primary text-white;
  }

  .select-input > input {
    @apply shadow-none focus:shadow-none focus:ring-0;
  }
}

.StripeElement {
  @apply rounded-md border border-gray-300 px-3 py-2;
}

.StripeElement--focus {
  @apply border-primary outline-none ring-2 ring-primary;
}
